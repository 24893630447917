<template>
  <div>
    <div class="banner_box">
      <img src="../assets/images/index_banner.png" alt="">
    </div>
    <div class="example_box">
      <div class="title">移动互联网开发</div>
      <div class="tab_box">
        <div :class="developIndex == 0 ? 'single_tab single_tab_active' : 'single_tab single_tab_first'"
          @click="developClick(0)">APP开发</div>
        <div :class="developIndex == 1 ? 'single_tab single_tab_active' : 'single_tab'" @click="developClick(1)">公众号开发
        </div>
        <div :class="developIndex == 2 ? 'single_tab single_tab_active' : 'single_tab'" @click="developClick(2)">小程序开发
        </div>
        <div :class="developIndex == 3 ? 'single_tab single_tab_active' : 'single_tab'" @click="developClick(3)">H5开发&系统开发
        </div>
      </div>

      <!-- <div class="example_content">
        <div class="content_img">
          <img src="../assets/images/index_img1.png" alt="">
        </div>
        <div class="conten_body">
          <div class="box1">
            <img src="../assets/images/phone_icon.png" alt="">
            <span>高端APP定制服务</span>
          </div>
          <div class="box2">
            <span>APP定制开发 智慧出行APP 智慧旅游APP</span>
            <span>社交直播APP 智慧酒店APP 文化教育APP</span>
            <span>更多定制+</span>
          </div>
        </div>
      </div> -->
      <template v-for="(item, index) in developList">
        <div class="example_content" :key="index" v-if="developIndex == index">
          <div class="content_img">
            <img :src="item.backImg" alt="" :class="'index_img' + index">
          </div>
          <div class="conten_body">
            <div class="box1">
              <img :src="item.icon" alt="">
              <span>{{ item.title }}</span>
            </div>
            <div class="box2">
              <span v-for="(text, textIndex) in item.textList" :key="textIndex">{{ text }}</span>
            </div>
          </div>
        </div>
      </template>

    </div>
    <div class="example_box back_box">
      <div class="title">智蚂优服发展历程</div>
      <div class="tab_box tab_box2">
        <div :class="experienceIndex == 0 ? 'single_tab single_tab_active' : 'single_tab single_tab_first'"
          @click="experienceClick(0)">初心</div>
        <div :class="experienceIndex == 1 ? 'single_tab single_tab_active' : 'single_tab'" @click="experienceClick(1)">转型
        </div>
        <div :class="experienceIndex == 2 ? 'single_tab single_tab_active' : 'single_tab'" @click="experienceClick(2)">成长
        </div>
        <div :class="experienceIndex == 3 ? 'single_tab single_tab_active' : 'single_tab'" @click="experienceClick(3)">未来
        </div>
      </div>
      <template>
        <div class="example_content">
          <div class="content_img">
            <span>{{ experienceList[experienceIndex].text }}</span>
            <!-- <span>
              帮助客户提供互联网思维，结合实体产业实现真正意义上的互联
            </span>
            <span>网转型</span> -->
          </div>
          <div class="conten_body">
            <img src="../assets/images/index_img5.png" alt="" class="img2">
          </div>
        </div>
      </template>

    </div>
    <div class="ex_box">
      <div class="single_ex">
        <img src="../assets/images/coo_img.png" alt="" class="single_img">
        <div class="text1">
          建立良好的合作
        </div>
        <div class="text2">1000+</div>
      </div>
      <div class="single_ex">
        <img src="../assets/images/tr_img.png" alt="" class="single_img">
        <div class="text1">
          助传统企业成功转型
        </div>
        <div class="text2">50+</div>
      </div>
      <div class="single_ex">
        <img src="../assets/images/ex_img.png" alt="" class="single_img">
        <div class="text1">
          软件研发经验
        </div>
        <div class="text2">12+</div>
      </div>
      <div class="single_ex last_single_ex">
        <img src="../assets/images/de_img.png" alt="" class="single_img">
        <div class="text1">
          行业细分
        </div>
        <div class="text2">20+</div>
      </div>
    </div>
    <div class="choose_box">
      <div class="choose_title">为什么选择我们</div>
      <div class="single_choose">
        <div class="single_left">
          <img src="../assets/images/choose_img1.png" alt="">
        </div>
        <div class="single_right">
          <div class="single_title">把项目当作品</div>
          <div class="single_text">不管是策划人员，项目管理人员，开发人员还是设计人员，把项目当作品，认真对待，而不仅仅只是完成任务。态度决定作品质量！</div>
        </div>
      </div>
      <div class="single_choose">
        <div class="single_left">
          <img src="../assets/images/choose_img2.png" alt="">
        </div>
        <div class="single_right">
          <div class="single_title">不仅仅只是美观，更重要的是用户体验</div>
          <div class="single_text">我们的设计师按照需求设计整套AppUI界面，根据使用人群及用户特点设计UI，不仅采用最流行的扁平化设计，还会使用最新的技术提升用户体验，比如iPhone8上的按压式屏幕。
          </div>
        </div>
      </div>
    </div>
    <div class="coo_box">
      <div class="coo_title">500+款应用选择我们</div>
      <img src="../assets/images/coo_group.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name: 'IndexPage',
  components: {

  },
  mixins: [],
  props: {

  },
  data() {
    return {
      developList: [{
        backImg: require("../assets/images/index_img1.png"),
        icon: require("../assets/images/index_icon1.png"),
        title: "高端APP定制服务",
        textList: ["APP定制开发	智慧出行APP   智慧旅游APP", "社交直播APP	智慧酒店APP   文化教育APP", "更多定制+"]
      }, {
        backImg: require("../assets/images/index_img2.png"),
        icon: require("../assets/images/index_icon2.png"),
        title: "公众号开发",
        textList: ["订阅号   服务号   企业号   定制开发", "微信商城  多级分销"]
      }, {
        backImg: require("../assets/images/index_img1.png"),
        icon: require("../assets/images/index_icon3.png"),
        title: "高端APP定制服务",
        textList: ["APP定制开发	智慧出行APP   智慧旅游APP", "社交直播APP	智慧酒店APP   文化教育APP", "更多定制+"]
      }, {
        backImg: require("../assets/images/index_img4.png"),
        icon: require("../assets/images/index_icon4.png"),
        title: "高端APP定制服务",
        textList: ["APP定制开发	智慧出行APP   智慧旅游APP", "社交直播APP	智慧酒店APP   文化教育APP", "更多定制+"]
      }],
      developIndex: 0,
      experienceList: [{
        text:"帮助客户提供互联网思维，结合实体产业实现真正意义上的互联网转型。"
      },{
        text:"智蚂优服科技正式转型，成为移动互联网高端定制服务商，坚信用技术+服务为客户提供美好的用户体验。品牌创造价值。"
      },{text:"将APP、小程序开发、微信开发、新技术应用为客户提供专业的技术，“专注创新，用心服务”是我们的态度。"},{text:"不忘初心，砥砺前行，为客户创造心动的产品和有利的商业价值，争做行业标杆，自立智蚂优服品牌。"}],
      experienceIndex: 0,
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  methods: {
    developClick(value) {
      this.developIndex = value
    },
    experienceClick(value) {
      this.experienceIndex = value
    }

  }
};
</script>
<style lang='scss' scoped>
@mixin flex($a: row,
  $b: wrap,
  $c: flex-start,
  $d: flex-start) {
  display: flex;
  flex-direction: $a;
  flex-wrap: $b;
  justify-content: $c;
  align-items: $d;
}

.banner_box {
  width: 100%;

  img {
    width: 100%;
    display: block;
  }
}

.example_box {
  margin-top: 58px;

  .title {
    font-size: 40px;
    color: #333333;
    text-align: center;
    font-weight: 500;
    margin-top: 41px;
  }

  .tab_box {
    height: 56px;
    width: 772px;
    margin: 40px auto 140px auto;
    @include flex(row, nowrap, center, center);

    .single_tab {
      width: 193px;
      height: 100%;
      border-top: 1px solid #D7D7E3;
      border-bottom: 1px solid #D7D7E3;
      border-right: 1px solid #D7D7E3;
      font-size: 18px;
      color: #333333;
      text-align: center;
      line-height: 56px;
      cursor: pointer;
    }

    .single_tab_active {
      width: 193px;
      height: 100%;
      border: 1px solid #3079FF;
      color: #3079FF;
    }

    .single_tab_first {
      border-left: 1px solid #D7D7E3;
    }
  }

  .tab_box2 {
    margin: 40px auto 94px auto;
  }

  .example_content {
    width: 100%;
    box-sizing: border-box;
    @include flex(row, nowrap, center, center);

    .content_img {
      width: 800px;

      img {
        width: 100%;
        display: block;
      }

      .index_img1 {
        width: 692px;
      }

      .index_img2 {
        width: 600px;
      }

      .index_img3 {
        width: 500px;
      }

      span {
        display: block;
        font-size: 20px;
        color: #333333;
        line-height: 40px;
      }
    }

    .conten_body {
      width: 458px;
      margin-left: 102px;

      .box1 {
        @include flex(row, nowrap, flex-start, center);

        img {
          width: 48px;
          height: 48px;
          margin-right: 12px;

        }

        span {
          font-size: 24px;
          color: #333333;
          font-weight: 500;
          white-space: nowrap;
        }
      }

      .box2 {
        padding-left: 14px;
        font-size: 20px;
        color: #333333;

        span {
          display: block;
          white-space: nowrap;
          line-height: 40px;
        }

      }

      .img2 {
        width: 439px;
      }
    }

  }

}

.back_box {
  box-sizing: border-box;
  padding-bottom: 58px;
  background-image: url('../assets/images/index_back.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 5px;
}

.ex_box {
  height: 390px;
  background: #110E20;
  @include flex(row, nowrap, center, center);

  .single_ex {
    margin-right: 180px;
    @include flex(column, nowrap, center, center);

    .single_img {
      width: 64px;
      height: 64px;
      display: block;
    }

    .text1 {
      font-size: 20px;
      color: #FFFFFF;
      line-height: 28px;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .text2 {
      color: #FFFFFF;
      font-size: 64px;
    }
  }

  .last_single_ex {
    margin-right: 0;
  }
}

.choose_box {
  padding: 160px 280px;

  .choose_title {
    color: #333333;
    font-size: 40px;
    text-align: center;
  }

  .single_choose {
    @include flex(row, nowrap, flex-start, center);
    margin-top: 74px;

    .single_left {
      width: 120px;

      img {
        width: 120px;
        height: 120px;
      }
    }

    .single_right {
      margin-left: 50px;
      width: calc(100% - 170px);

      .single_title {
        color: #333333;
        font-size: 28px;
        line-height: 40px;
      }

      .single_text {
        font-size: 20px;
        line-height: 28px;
        color: #666666;
      }
    }
  }
}

.coo_box {
  width: 100%;
  padding: 6px 39px 160px 39px;
  box-sizing: border-box;

  .coo_title {
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 84px;
    text-align: center;
    color: #333333;

  }

  img {
    width: 100%;
  }
}</style>
