import VueRouter from 'vue-router';
import IndexPage from "../views/index.vue"
import Navigate from "../views/navigate.vue"
const router = new VueRouter({
    routes: [{
        path: "/",
        name: 'index',
        component: Navigate,
        children:[{
            path: "/index",
            name: 'index',
            component: IndexPage,
        }]
    }]
})
export {
    router
}

