<template>
  <div class="service_box">
    <div class="banner_box">
      <img src="../assets/images/service_banner.png" alt="">
    </div>
    <div class="href_box">
      <a href="#advance">我们的优势</a>
      <a href="#cooperate">合作流程</a>
      <a href="#s">服务体系</a>
    </div>
    <div class="location_box" id="advance">
      <div class="location_title">我们的优势</div>
      <div class="single_location">
        <div class="location_left">
          <img src="../assets/images/service1.png" alt="" class="pro_img">
        </div>
        <div class="location_right">
          <div class="app_title">优质服务</div>
          <div class="app_text">智蚂优服一直致力于互联网行业研究与开发，打破传统，专注创新，以客户为上帝，打造属于您的专属产品。</div>
        </div>
      </div>
      <div class="single_location">
        <div class="location_right">
          <div class="app_title">精湛的技术</div>
          <div class="app_text">精通JAVA、OBJECT、HTML5、MYSQL等开发系统架构，积累1000+成熟项目经验。</div>
        </div>
        <div class="location_left float_right">
          <img src="../assets/images/service2.png" alt="" class="pro_img">
        </div>
      </div>
      <div class="single_location">
        <div class="location_left">
          <img src="../assets/images/service3.png" alt="" class="pro_img">
        </div>
        <div class="location_right">
          <div class="app_title">一流的研发团队</div>
          <div class="app_text">公司技术团队均从业4年以上，我们善于深入业务，把控每一个细节，利用丰富的行业通理与产品经验打造落地的产品。</div>
        </div>
      </div>
      <div class="single_location">
        <div class="location_right">
          <div class="app_title">高性价比</div>
          <div class="app_text">已合作客户达1000+，其中长期合作客户达500+，获得了客户最大的信任；保持初心，坚持创新，用设计+技术为客户带来美好的用户体验。</div>
        </div>
        <div class="location_left float_right">
          <img src="../assets/images/service4.png" alt="" class="pro_img">
        </div>
      </div>
    </div>
    <div class="coo_box" id="cooperate">
      <div class="coo_title">合作流程</div>
      <img src="../assets/images/service6.png" alt="" class="coo_img">
      <div class="coo_btn">立即咨询</div>
    </div>
    <div class="service_sys" id="service">
      <div class="service_title">服务体系</div>
      <div class="sys_box">
        <div class="single_sys">
          <div class="title_box">（一）项目服务时限</div>
          <div class="sys_text">部署交付的项目我司均提供一年免费维护，保证正常运行</div>
        </div>
        <div class="single_sys">
          <div class="title_box">（二）项目服务响应</div>
          <div class="sys_text">随时解答客户在使用过程中的疑问，产品出现bug在7*24小时电话/微信响应，对于通过电话或远程无法解决的系统故障，安排售后工程师到现场解决问题</div>
        </div>
        <div class="single_sys">
          <div class="title_box">（三）项目回访响应</div>
          <div class="sys_text">定期/不定期回访调查客户情况，及时听取客户意见/建议</div>
        </div>
        <div class="single_sys">
          <div class="title_box">（四）项目运营支持维护</div>
          <div class="sys_text">项目上线后运营过程中，提供项目运营支持服务、运营思维创新、投融资建议</div>
        </div>
        <div class="single_sys">
          <div class="title_box">（五）项目落地升级维护</div>
          <div class="sys_text">
            项目上线推向市场，趋势良好，用户量激增，可提供对于系统承载量的升级服务建议方案，升级方案部署项目上线推向市场，趋势良好，用户量激增，可提供对于系统承载量的升级服务建议方案，升级方案部署</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexPage',
  components: {

  },
  mixins: [],
  props: {

  },
  data() {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  methods: {

  }
};
</script>

<style lang='scss' scoped>
@mixin flex($a: row, $b: wrap, $c: flex-start, $d: flex-start) {
  display: flex;
  flex-direction: $a;
  flex-wrap: $b;
  justify-content: $c;
  align-items: $d;
}

.service_box {
  .banner_box {
    width: 100%;

    img {
      width: 100%;
      display: block;
    }
  }

  .href_box {
    width: 100%;
    height: 80px;
    background: #393B47;
    padding-left: 584px;
    flex: 1;
    @include flex(row, nowrap, flex-start, center);

    a {
      color: #FFFFFF;
      font-size: 16px;
      margin-right: 264px;
    }
  }

  .location_box {
    padding: 80px 258px 120px 258px;

    .location_title {
      font-size: 40px;
      line-height: 56px;
      color: #333333;
      font-weight: 500;
      text-align: center;
    }

    .single_location {
      margin-top: 76px;
      @include flex(row, nowrap, space-between, center);

      .location_left {
        width: 710px;
        @include flex(column, nowrap, flex-start, flex-start);

        .pro_img {
          width: 450px;
          display: block;
        }

        .pro_img2 {
          width: 622px;
          display: block;
        }

        .app_name {
          width: 568px;
          font-size: 20px;
          line-height: 28px;
          color: #999999;
          text-align: center;
        }
      }

      .float_right {
        @include flex(column, nowrap, flex-end, flex-end);
      }

      .location_right {
        width: calc(100% - 710px);

        .app_title {
          font-size: 32px;
          line-height: 45px;
          color: #333333;
          font-weight: 500;
          margin-bottom: 32px;
        }

        .des_box {
          @include flex(row, nowrap, flex-start, center);

          span {
            display: inline-block;
            font-size: 32px;
            line-height: 45px;
            color: #333333;
            margin-right: 12px;
          }

          img {
            width: 46px;
          }
        }

        .app_text {
          font-size: 20px;
          line-height: 28px;
          color: #666666;
          margin-top: 24px;
        }
      }
    }
  }

  .coo_box {
    background: #FBFBFB;
    padding: 56px 280px;

    .coo_title {
      font-size: 40px;
      line-height: 56px;
      color: #333333;
      font-weight: 500;
      margin-bottom: 80px;
      text-align: center;
    }

    .coo_img {
      width: 100%;
      display: block;
      margin-bottom: 60px;
    }

    .coo_btn {
      width: 180px;
      height: 60px;
      background: #3079FF;
      border-radius: 4px;
      margin: auto;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 60px;
      text-align: center;
    }
  }

  .service_sys {
    padding: 120px 0 465px 280px;

    .service_title {
      font-size: 40px;
      line-height: 56px;
      color: #333333;
      font-weight: 500;
      margin-bottom: 10px;
      text-align: center;
    }

    .sys_box {
      @include flex(row, wrap, flex-start, flex-start);

      .single_sys {
        width: 386px;
        height: 300px;
        background: #FFFFFF;
        box-shadow: 0px 6px 26px 0px rgba(0, 0, 0, 0.1);
        margin-top: 70px;
        margin-right: 100px;
        padding: 32px;

        .title_box {
          padding: 0 0 32px 0;
          border-bottom: 1px solid #DBDBDB;
          font-size: 24px;
          line-height: 33px;
          color: #333333;
          font-weight: 500;
        }

        .sys_text {
          margin-top: 22px;
          font-size: 16px;
          line-height: 32px;
          color: #333333;
        }
      }
    }
  }
}
</style>
 