<template>
  <div class="example_container">
    <div class="ex_banner">
      <Carousel autoplay loop>
        <CarouselItem>
          <div class="">
            <img src="../assets/images/ex_banner1.png" alt="" class="banner_img">
          </div>
        </CarouselItem>
        <CarouselItem>
          <div class="">
            <img src="../assets/images/ex_banner2.png" alt="" class="banner_img">
          </div>
        </CarouselItem>
        <CarouselItem>
          <div class="">
            <img src="../assets/images/ex_banner3.png" alt="" class="banner_img">
          </div>
        </CarouselItem>
        <CarouselItem>
          <div class="">
            <img src="../assets/images/ex_banner4.png" alt="" class="banner_img">
          </div>
        </CarouselItem>
        <CarouselItem>
          <div class="">
            <img src="../assets/images/ex_banner5.png" alt="" class="banner_img">
          </div>
        </CarouselItem>
        <CarouselItem>
          <div class="">
            <img src="../assets/images/ex_banner6.png" alt="" class="banner_img">
          </div>
        </CarouselItem>
      </Carousel>
    </div>
    <div class="app_content">
      <img src="../assets/images/font_img.png" alt="" class="font_img">
      <div class="tab_list">
        <Tabs :value="tabValue">
          <!-- <TabPane label="在线教育" name="name1">
            <div>111</div>
          </TabPane>
          <TabPane label="标签二" name="name2">标签二的内容</TabPane>
          <TabPane label="标签三" name="name3">标签三的内容</TabPane> -->
          <template v-for="(item, index) in tabList">
            <TabPane :label="item.label" :name="item.name" :key="index">
              <div class="img_box">
                <div class="single_img" v-for="(img) in item.imgList" :key="img">
                  <img :src="img" alt="">
                </div>
              </div>
             
            </TabPane>
          </template>
        </Tabs>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'IndexPage',
  components: {

  },
  mixins: [],
  props: {

  },
  data() {
    return {
      tabValue: "education",
      tabList: [
        {
          label: "在线教育",
          name: "education",
          count:10,
          imgList:[require("../assets/images/education1.png"),require("../assets/images/education2.png"),require("../assets/images/education3.png"),require("../assets/images/education4.png"),require("../assets/images/education5.png"),require("../assets/images/education6.png"),require("../assets/images/education7.png"),require("../assets/images/education8.png"),require("../assets/images/education9.png"),require("../assets/images/education10.png")]
        },
        {
          label: "智慧出行",
          name: "travel",
          count:13,
          imgList: [require("../assets/images/travel1.png"),require("../assets/images/travel2.png"),require("../assets/images/travel3.png"),require("../assets/images/travel4.png"),require("../assets/images/travel5.png"),require("../assets/images/travel6.png"),require("../assets/images/travel7.png"),require("../assets/images/travel8.png"),require("../assets/images/travel9.png"),require("../assets/images/travel10.png"),require("../assets/images/travel11.png"),require("../assets/images/travel12.png"),require("../assets/images/travel13.png")]
        },
        {
          label: "社交娱乐",
          name: "social",
          count:13,
          imgList:  [require("../assets/images/social1.png"),require("../assets/images/social2.png"),require("../assets/images/social3.png"),require("../assets/images/social4.png"),require("../assets/images/social5.png"),require("../assets/images/social6.png"),require("../assets/images/social7.png"),require("../assets/images/social8.png"),require("../assets/images/social9.png"),require("../assets/images/social10.png"),require("../assets/images/social11.png"),require("../assets/images/social12.png"),require("../assets/images/social13.png")]
        },
        {
          label: "生鲜配送",
          name: "fresh",
          imgList:  [require("../assets/images/fresh1.png"),require("../assets/images/fresh2.png"),require("../assets/images/fresh3.png")]
        },
        {
          label: "酒店旅游",
          name: "hotel",
          imgList:  [require("../assets/images/hotel1.png"),require("../assets/images/hotel2.png"),require("../assets/images/hotel3.png"),require("../assets/images/hotel4.png"),require("../assets/images/hotel5.png")]
        },
        {
          label: "移动电商",
          name: "mobile",
          count:  [require("../assets/images/mobile1.png"),require("../assets/images/mobile2.png"),require("../assets/images/mobile3.png"),require("../assets/images/mobile4.png"),require("../assets/images/mobile5.png"),require("../assets/images/mobile6.png"),require("../assets/images/mobile7.png"),require("../assets/images/mobile8.png"),require("../assets/images/mobile9.png"),require("../assets/images/mobile10.png"),require("../assets/images/mobile11.png"),require("../assets/images/mobile12.png")]
        }, {
          label: "其他行业",
          name: "other",
          imgList:[require("../assets/images/other1.png"),require("../assets/images/other2.png"),require("../assets/images/other3.png"),require("../assets/images/other4.png"),require("../assets/images/other5.png")]
        }
      ]
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  methods: {

  }
};
</script>
<style lang='scss' scoped>
/deep/.ivu-tabs-nav .ivu-tabs-tab-active {
  color: #3079FF;
  font-size: 18px;
}

/deep/.ivu-tabs-nav .ivu-tabs-tab {
  color: #666666;
  font-size: 18px;
}

/deep/.ivu-tabs-nav {
  width: 100%;
  flex: 1;
}
@mixin flex($a: row,
  $b: wrap,
  $c: flex-start,
  $d: flex-start) {
  display: flex;
  flex-direction: $a;
  flex-wrap: $b;
  justify-content: $c;
  align-items: $d;
}
.example_container {
  .banner_img {
    width: 100%;
    display: block;
  }

  .app_content {
    .font_img {
      width: 641px;
      height: 48px;
      display: block;
      margin: 84px auto 101px auto;
    }

    .tab_list {
      padding: 0 280px 545px 280px;

      .img_box {
        padding: 25px 0 0 64px;
        @include flex(row,wrap,flex-start,flex-start);
        .single_img {
          width: 200px;
          height: 200px;
          margin-right: 54px;
          img{
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
  }
}</style>
 