<template>
    <div>
        <div class="navigate_box">
            <div class="logo_box" @click="tabChange('1')">
              <img src="../assets/images/logo.png" alt="">
            </div>
            <div class="menu_box">
                <Tabs :value="tabIndex" @on-click="tabChange">
                    <TabPane label="首页" name="1"></TabPane>
                    <TabPane label="产品" name="2"></TabPane>
                    <TabPane label="案例" name="3"></TabPane>
                    <TabPane label="服务" name="4"></TabPane>
                    <TabPane label="我们" name="5"></TabPane>
                </Tabs>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IndexPage',
    components: {

    },
    mixins: [],
    props: {
      tabIndex:{
        type:String,
        default:"1"
      }
    },
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    methods: {
      tabChange(e) {
        console.log("tab点击")
        console.log(e)
        this.$emit("tabIndexChange",e)
      }
    }
};
</script>

<style lang='scss' scoped>
@import url("../styles/public.scss");
@mixin flex($a: row,
$b: wrap,
$c: flex-start,
$d: flex-start) {
    display: flex;
    flex-direction: $a;
    flex-wrap: $b;
    justify-content: $c;
    align-items: $d;
}

.navigate_box {
    padding: 0 280px;
    width: 100%;
    height: 70px;
    background: linear-gradient(90deg, #08051B 0%, #1B1960 47%, #070417 100%);
    @include flex(row,
    nowrap,
    space-between,
    center);
    .logo_box {
      width: 121px;
      height: 49px;
      img{
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
   
}
/deep/.ivu-tabs-bar {
  border-bottom: none!important;
  margin-bottom: 0!important;
}
/deep/.ivu-tabs-nav .ivu-tabs-tab-active{
  color: #3079FF;
  font-size: 18px;
}
/deep/.ivu-tabs-nav .ivu-tabs-tab{
  color: #FFFFFF;
  font-size: 18px;
}
</style>
 