<template>
  <div class="us_container">
    <div class="us_banner">
      <img src="../assets/images/us_banner.png" alt="">
    </div>
    <div class="us_content">
      <div class="us_title">关于我们</div>
      <div class="single_box">
        <div class="img_box">
          <img class="img1" src="../assets/images/us_img1.png" alt="">
        </div>
        <div class="text_box">
          <span>智蚂优服（成都）科技有限公司是一个集前期市场调研、项目策划、项目整体研发、部署、后期持续服务的信息智能集成解决方案服务商。拥有成都和绵阳两家公司。（绵阳拓跃科技）</span>
        </div>
      </div>
      <div class="single_box">

        <div class="text_box">
          <span>智蚂优服科技共计36人，14名服务端开发工程师，6名前端开发工程师，2名策划师，2名产品经理，2名原画设计师，2名UI/UE设计师，4名测试工程师，2名运维工程师，2名售前工程师。
            团队所有成员最低工作年限4年+，最高10年+，累计参与开发项目130款+，历史累计涉足行业20+，精英团队4组+，84%为技术人员。
            </span>
        </div>
        <div class="img_box">
          <img class="img2" src="../assets/images/us_img2.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'IndexPage',
  components: {

  },
  mixins: [],
  props: {

  },
  data() {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  methods: {

  }
};
</script>
<style lang='scss' scoped>
@mixin flex($a: row,
  $b: wrap,
  $c: flex-start,
  $d: flex-start) {
  display: flex;
  flex-direction: $a;
  flex-wrap: $b;
  justify-content: $c;
  align-items: $d;
}

.us_container {
  .us_banner {
    width: 100%;

    img {
      width: 100%;
      display: block;
    }
  }

  .us_content {
    padding: 72px 280px 246px 280px;

    .us_title {
      font-size: 40px;
      line-height: 56px;
      color: #333333;
      font-weight: 500;
      text-align: center;
      margin-bottom: 43px;
    }

    .single_box {
      width: 100%;
      @include flex(row, nowrap, space-between, center);

      .img_box {
        width: 566px;

        .img1 {
          width: 455px;
          float: left;
        }

        .img2 {
          width: 450px;
          float: right;
        }
      }

      .text_box {
        width: calc(100% - 566px);
        span {
          font-size: 20px;
          line-height: 36px;
          color: #666666;
          display: block;
        }
      }
    }
  }
}
</style>
 