import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import  {router} from "./router/index"
import iView from 'iview'
import 'iview/dist/styles/iview.css'
import './styles/public.scss'
import 'lib-flexible/flexible'
import px2rem from '@/utils/px2rem'
import flexible from 'amfe-flexible' 
Vue.use(flexible);
Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(iView)
Vue.prototype.$px2rem = px2rem
console.log("路由数据")
console.log(router)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
