<template>
  <div id="app">
    <Navigate :tabIndex="tabIndex" @tabIndexChange="tabIndexChange" />
    <div class="index_content">
      <Index v-if="tabIndex == 1" />
      <Production v-if="tabIndex == 2" />
      <Example v-if="tabIndex == 3" />
      <Service v-if="tabIndex == 4" />
      <AboutUs v-if="tabIndex == 5" />
    </div>
    <div class="message_box">
      <div class="mes_box">
        <div class="img_box1" @mouseenter="mouseEnter('phone_box', 'enter')" @mouseleave="mouseEnter('phone_box', 'leave')">
        </div>
        <div class="phone_box">
          <div class="phone_text">在线客户（工作日：9:30-18:30）</div>
          <div>19308094768</div>
        </div>
      </div>
      <div class="mes_box">
        <div class="img_box2" @mouseenter="mouseEnter('qq_box', 'enter')" @mouseleave="mouseEnter('qq_box', 'leave')">
        </div>
        <div class="qq_box">
          <div>QQ：2753930600</div>
        </div>
      </div>
      <div class="mes_box">
        <div class="img_box3" @mouseenter="mouseEnter('wechat_box', 'enter')"
          @mouseleave="mouseEnter('wechat_box', 'leave')">
        </div>
        <div class="wechat_box">
          <div class="code_box">
            <img src="./assets/images/wechat_code.pic.jpg" alt="">
          </div>
          <!-- <div class="text_box">商务咨询（13618393558）</div>
          <div class="text_box">7*24小时</div> -->
        </div>
      </div>
      <div class="mes_box" @click="backToTop">
        <img src="./assets/images/mes_arrow.png" alt="">
      </div>
    </div>
    <Footer />
    <!-- <router-view></router-view> -->
  </div>
</template>

<script>
import Navigate from './views/navigate.vue';
import Index from './views/index.vue';
import Footer from './views/footer.vue'
import Production from './views/production.vue'
import Example from './views/example.vue'
import AboutUs from './views/about_us.vue'
import Service from './views/service.vue'
export default {
  name: 'App',
  components: {
    Navigate,
    Index,
    Footer,
    Production,
    Example,
    AboutUs,
    Service
  },
  data() {
    return {
      tabIndex: "1"
    }
  },
  methods: {
    tabIndexChange(value) {
      this.tabIndex = value
      console.log("修改tab值")
      console.log(value)
      console.log(this.tabIndex)
    },
    mouseEnter(className, type) {
      console.log("鼠标移入")
      const element = document.getElementsByClassName(className)
      console.log(element)
      if (type == "enter") {
        element[0].style.opacity = 1

      } else {
        element[0].style.opacity = 0

      }
    },
    backToTop(){
      window.scrollTo({
        top:0,
        left:0,
        behavior:"smooth"
      })
    }
  }
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}

@mixin flex($a: row,
  $b: wrap,
  $c: flex-start,
  $d: flex-start) {
  display: flex;
  flex-direction: $a;
  flex-wrap: $b;
  justify-content: $c;
  align-items: $d;
}

#app {}

.index_content {
  box-sizing: border-box;
}

.message_box {
  width: 60px;
  height: 240px;
  position: fixed;
  top: 745px;
  right: 32px;
  z-index: 99;
  background: #999999;

  .mes_box {
    img {
      width: 100%;
      height: 100%;
      display: block;
    }

    .img_box1 {
      width: 60px;
      height: 60px;
      background-image: url("./assets/images/mes_phone.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
    }

    .img_box2 {
      width: 60px;
      height: 60px;
      background-image: url("./assets/images/mes_qq.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
    }

    .img_box3 {
      width: 60px;
      height: 60px;
      background-image: url("./assets/images/mes_wechat.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
    }

    .phone_box {
      width: 287px;
      height: 76px;
      background: #3079FF;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 22px;
      position: absolute;
      right: 80px;
      top: -8px;
      padding: 0 17px;
      border-radius: 4px;
      opacity: 0;
      @include flex(column, wrap, center, flex-start);

      .phone_text {
        margin-bottom: 10px;
      }
    }

    .qq_box {
      width: 287px;
      height: 58px;
      background: #3079FF;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 22px;
      position: absolute;
      right: 80px;
      top: 60px;
      border-radius: 4px;
      opacity: 0;
      @include flex(column, wrap, center, center);
    }
    .wechat_box{
      width: 230px;
      height: 230px;
      position: absolute;
      top:40px;
      right: 80px;
      background: #3079FF;
      border-radius: 4px;
      opacity: 0;
      @include  flex(column,wrap,center,center);
      .code_box{
        width: 144px;
        height: 144px;
        display: block;
        margin-bottom: 8px;
      }
      .text_box{
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
      }
    }

    .qq_box::after {
      content: "";
      position: absolute;
      right: -9px;
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
      background: #3079FF;
    }

    .phone_box::after {
      content: "";
      position: absolute;
      right: -10px;
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
      background: #3079FF;
    }
    .wechat_box::after {
      content: "";
      position: absolute;
      right: -10px;
      width: 20px;
      height: 20px;
      top:100px;
      transform: rotate(45deg);
      background: #3079FF;
    }

    .img_box1:hover {
      background-image: url("./assets/images/mes_phone2.png");
    }

    .img_box2:hover {
      background-image: url("./assets/images/mes_qq2.png");
    }
    .img_box3:hover {
      background-image: url("./assets/images/mes_wechat2.png");
    }
  }
}</style>
