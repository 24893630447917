<template>
  <div class="product_box">
    <div class="ex_banner">
      <Carousel autoplay loop>
        <CarouselItem>
          <div class="">
            <img src="../assets/images/ex_banner1.png" alt="" class="banner_img">
          </div>
        </CarouselItem>
        <CarouselItem>
          <div class="">
            <img src="../assets/images/ex_banner2.png" alt="" class="banner_img">
          </div>
        </CarouselItem>
        <CarouselItem>
          <div class="">
            <img src="../assets/images/ex_banner3.png" alt="" class="banner_img">
          </div>
        </CarouselItem>
        <CarouselItem>
          <div class="">
            <img src="../assets/images/ex_banner4.png" alt="" class="banner_img">
          </div>
        </CarouselItem>
        <CarouselItem>
          <div class="">
            <img src="../assets/images/ex_banner5.png" alt="" class="banner_img">
          </div>
        </CarouselItem>
        <CarouselItem>
          <div class="">
            <img src="../assets/images/ex_banner6.png" alt="" class="banner_img">
          </div>
        </CarouselItem>
      </Carousel>
    </div>
    <div class="href_box">
      <a href="#app">APP开发</a>
      <a href="#we-app">小程序开发</a>
      <a href="#we-web">公众号开发</a>
      <a href="#h5">h5开发</a>
      <a href="#system">系统开发</a>
    </div>
    <div class="location_box">
      <div id="app" class="single_location">
        <div class="location_left">
          <img src="../assets/images/pro1.png" alt="" class="pro_img">
          <div class="app_name">易和仓淘</div>
        </div>
        <div class="location_right">
          <div class="app_title">APP高端定制开发</div>
          <div class="des_box">
            <span>iOS&Android</span>
            <img src="../assets/images/pro_icon1.png" alt="">
          </div>
          <div class="app_text">APP定制开发、智慧出行APP 、智慧旅游APP 、社交直播APP 、智慧酒店APP 、文化教育APP</div>
        </div>
      </div>
      <div id="we-app" class="single_location">
        <div class="location_right">
          <div class="app_title">小程序定制开发</div>
          <div class="des_box">
            <span>小程序</span>
            <img src="../assets/images/pro_icon2.png" alt="">
          </div>
          <div class="app_text">小程序应用、小程序商城、小程序一对一教学、基于 LBS 小程序开发等</div>
        </div>
        <div class="location_left float_right">
          <img src="../assets/images/pro2.png" alt="" class="pro_img">
          <div class="app_name">公交网约车小程序</div>
        </div>
      </div>
      <div id="we-web" class="single_location">
        <div class="location_left">
          <img src="../assets/images/pro3.png" alt="" class="pro_img">
          <div class="app_name">金杯·购房通</div>
        </div>
        <div class="location_right">
          <div class="app_title">公众号开发</div>
          <div class="des_box">
            <span>公众号</span>
            <img src="../assets/images/pro_icon2.png" alt="">
          </div>
          <div class="app_text">订阅号、服务号、企业号、定制开发、微信商城、多级分销等</div>
        </div>
      </div>
      <div id="h5" class="single_location">
        <div class="location_right">
          <div class="app_title">H5定制</div>
          <div class="des_box">
            <span>H5定制</span>
            <img src="../assets/images/pro_icon1.png" alt="">
          </div>
          <div class="app_text">H5商城、 H5制作、 H5开发、 H5设计等</div>
        </div>
        <div class="location_left float_right">
          <img src="../assets/images/pro4.png" alt="" class="pro_img">
          <div class="app_name">金杯·购房通</div>
        </div>
      </div>
      <div id="system" class="single_location">
        <div class="location_left">
          <img src="../assets/images/pro5.png" alt="" class="pro_img2">
          <div class="app_name">OA系统</div>
        </div>
        <div class="location_right">
          <div class="app_title">系统开发</div>
          <div class="des_box">
            <span>系统定制</span>
            <img src="../assets/images/pro_icon1.png" alt="">
          </div>
          <div class="app_text">OA系统、 ERP系统、 CRM系统、 HR系统等</div>
        </div>
      </div>
    </div>
    <div class="produce_box">
      <div class="single_pro">
        <img src="../assets/images/app1.png" alt="" class="app_img">
        <div class="pro_title">APP定制开发</div>
        <div class="text_box">
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">iOS系统、Android主流系统原生APP开发</div>
          </div>
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">提供空间域名，源文件+源代码交付</div>
          </div>
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">定制开发解决方案，设计到满意为止</div>
          </div>
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">智慧出行APP、智慧旅游APP、社交直播APP文化教育APP、智慧酒店 APP、生鲜电商APP</div>
          </div>
        </div>
      </div>
      <div class="single_pro">
        <img src="../assets/images/app2.png" alt="" class="app_img">
        <div class="pro_title">小程序定制开发</div>
        <div class="text_box">
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">提供空间域名，源文件+源代码交付</div>
          </div>
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">定制开发解决方案</div>
          </div>
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">设计到满意为止</div>
          </div>
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">小程序应用、小程序商城、小程序一对一教学、基于 LBS 小程序开发D等</div>
          </div>
        </div>
      </div>
      <div class="single_pro">
        <img src="../assets/images/app3.png" alt="" class="app_img">
        <div class="pro_title">公众号开发</div>
        <div class="text_box">
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">提供空间域名，源文件+源代码交付</div>
          </div>
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">定制开发解决方案</div>
          </div>
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">设计到满意为止</div>
          </div>
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">订阅号、服务号、企业号、定制开发、微信商城、多级分销等</div>
          </div>
        </div>
      </div>
      <div class="single_pro">
        <img src="../assets/images/app4.png" alt="" class="app_img">
        <div class="pro_title">系统开发</div>
        <div class="text_box">
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">提供空间域名，源文件+源代码交付</div>
          </div>
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">定制开发解决方案</div>
          </div>
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">设计到满意为止</div>
          </div>
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">OA系统、ERP系统、CRM系统、HR 系统等</div>
          </div>
        </div>
      </div>
      <div class="single_pro">
        <img src="../assets/images/app5.png" alt="" class="app_img">
        <div class="pro_title">H5定制开发</div>
        <div class="text_box">
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">提供空间域名，源文件+源代码交付</div>
          </div>
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">定制开发解决方案</div>
          </div>
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">设计到满意为止</div>
          </div>
          <div class="single_text">
            <div class="radius"></div>
            <div class="text_wrap">H5商城、 H5制作、 H5开发、 H5设计等</div>
          </div>
        </div>
      </div>
      <div class="single_pro">
        <img src="../assets/images/app6.png" alt="" class="app_img">
        <div class="pro_title">全面设计</div>
        <div class="text_box ">
          <div class="single_text special_text">
            <div class="radius"></div>
            <div class="text_wrap">专业产品经理全程服务</div>
          </div>
          <div class="single_text special_text">
            <div class="radius"></div>
            <div class="text_wrap ">100%互联网工作流程</div>
          </div>
          <div class="single_text special_text">
            <div class="radius"></div>
            <div class="text_wrap">保证原创、提供源文件</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexPage',
  components: {

  },
  mixins: [],
  props: {

  },
  data() {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  methods: {

  }
};
</script>

<style lang='scss' scoped>
@mixin flex($a: row, $b: wrap, $c: flex-start, $d: flex-start) {
  display: flex;
  flex-direction: $a;
  flex-wrap: $b;
  justify-content: $c;
  align-items: $d;
}

.product_box {
  .banner_img {
    width: 100%;
    display: block;
  }

  .href_box {
    width: 100%;
    height: 80px;
    background: #393B47;
    padding-left: 420px;
    @include flex(row, nowrap, flex-start, center);

    a {
      color: #FFFFFF;
      font-size: 16px;
      padding-right: 185px;
    }
  }

  .location_box {
    padding: 0 258px 120px 258px;

    .single_location {
      margin-top: 76px;
      @include flex(row, nowrap, space-between, center);

      .location_left {
        width: 700px;
        @include flex(column, nowrap, flex-start, flex-start);

        .pro_img {
          width: 568px;
          display: block;
        }

        .pro_img2 {
          width: 622px;
          display: block;
        }

        .app_name {
          width: 568px;
          font-size: 20px;
          line-height: 28px;
          color: #999999;
          text-align: center;
        }
      }

      .float_right {
        @include flex(column, nowrap, flex-end, flex-end);
      }

      .location_right {
        width: calc(100% - 700px);

        .app_title {
          font-size: 44px;
          line-height: 62px;
          color: #333333;
          font-weight: 500;
          margin-bottom: 8px;
        }

        .des_box {
          @include flex(row, nowrap, flex-start, center);

          span {
            display: inline-block;
            font-size: 32px;
            line-height: 45px;
            color: #333333;
            margin-right: 12px;
          }

          img {
            width: 46px;
          }
        }

        .app_text {
          font-size: 20px;
          line-height: 28px;
          color: #666666;
          margin-top: 24px;
        }
      }
    }
  }

  .produce_box {
    padding: 0 280px 64px 260px;
    @include flex(row, wrap, flex-start, center);

    .single_pro {
      margin-bottom: 56px;
      width: 440px;
      height: 480px;
      background: #FFFFFF;
      margin-left: 19px;
      padding: 48px 28px;
      box-shadow: 0px 6px 13px 0px rgba(48, 121, 255, 0.15), 0px -6px 13px 0px rgba(48, 121, 255, 0.15);

      .app_img {
        width: 125px;
        height: 125px;
        display: block;
        margin: auto;
      }

      .pro_title {
        font-size: 32px;
        line-height: 45px;
        margin: 20px 0;
        color: #333333;
        text-align: center;
      }

      .text_box {
        .single_text {
          margin-bottom: 16px;
          font-size: 18px;
          line-height: 25px;
          color: #666666;
          @include flex(row, nowrap, flex-start, flex-start);
        }
        .special_text{
          @include flex(row, nowrap, center, center);
          .text_wrap{
            width: auto;
          }
        }

        .radius {
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background: #3079FF;
          margin-right: 8px;
          margin-top: 8px;
        }

        .text_wrap {
          width: calc(100% - 16px);
        }
      }
    }
  }
}
</style>
 